$(document).ready(function(){

    function animateToSection(sectionId) {

        if( !$(sectionId) || ($(sectionId).length == 0) ) return;

        var headerHeight = $('#header').outerHeight();
        var adminbarHeight = 0;

        if( $('#wpadminbar').length > 0 ) {
            adminbarHeight = $('#wpadminbar').outerHeight();
        }

        $('html, body').animate({
            scrollTop: $(sectionId).offset().top - ( headerHeight + adminbarHeight )
        }, 1000);

        window.location.hash = sectionId;
    }

    var href = window.location.href;
    var hashLocation = href.indexOf('#');
    if( hashLocation >= 0 ) {
        var sectionId = href.substring(hashLocation);
        animateToSection(sectionId);
    }

    $('a').click(function(e){
        var href = e.target.href;
        var hashLocation = href.indexOf('#');
        if( hashLocation >= 0 ) {
            e.preventDefault();

            var sectionId = href.substring(hashLocation);
            animateToSection(sectionId);
        }
    })
})
