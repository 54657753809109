var mobileBreak = 99999999;

function resetHeaderMenu() {
    var header = $('#header');
    var menuHeader = $('#menuHeader');
    var menuHeaderToggler = $('#menuHeaderToggler');

    header.data('state','resetting');

    menuHeader.removeClass('show');
    menuHeaderToggler.removeClass('show');

    // $('body').css({'overflow-y':'visible'});

    header.data('state','default');
}

$('#menuHeaderToggler').click(function(){
    var menuHeader = $('#menuHeader');
    var header = $('#header');

    if( $(this).hasClass('show') ) {
        header.data('state','menuClosing');
        menuHeader.removeClass('show');
        $(this).removeClass('show');
        menuHeader.animate({ opacity : 0 }, 300);

        setTimeout(function(){
            menuHeader.css({display:'none'});
            header.data('state','menuClosed');
            // $('body').css({'overflow-y':'visible'});
        }, 300);
    } else {
        header.data('state','menuOpening');
        menuHeader.addClass('show');
        $(this).addClass('show');
        menuHeader.css({display:'block'});
        menuHeader.animate({ opacity : 1 }, 300);

        setTimeout(function(){
            header.data('state','menuOpen');
            // $('body').css({'overflow-y':'hidden'});
        }, 300)
    }
})

$(document).ready(function(){
    var header = $('#header');

    if( $(window).width() < mobileBreak ) {
        var menuHeader = $('#menuHeader');

        menuHeader.animate({ opacity : 0 });
    }

    header.data('state','default');
})

$(window).resize(function(){
    resetHeaderMenu();
})
